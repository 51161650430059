import React from "react";
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import ProjectsPage from "./components/ProjectsPage";

const AppRouter = () => {
	return (
		<Router>
			<NavBar />
			<Routes>
					<Route path="/" exact component={Home} />
					<Route path="/ProjectsPage" element={<ProjectsPage />} />
			</Routes>
		</Router>
	);
};

export default AppRouter;
