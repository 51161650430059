import React from "react";

const ProjectsPage = () => {
  return (
    <div>
      <h1>Project Demo</h1>
      <p>This is the Project demo page.</p>
    </div>
  );
};

export default ProjectsPage;