import { Col, Container, Row, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/Galaxy_elevator_logo.png";
import projImg2 from "../assets/wedding_photo.jpg";
import projImg3 from "../assets/github.png";

export const Projects = () => {
	const projects = [
		{
			title: "Galaxy Elevator",
			description: "Made with Wordpress",
			imgUrl: projImg1,
			url: "galaxy-elevator.com",
		},
		{
			title: "Wedding website",
			description: "Made with React",
			imgUrl: projImg2,
			url: "bayaniwedding.com",
		},{
			title: "Project Demo",
			description: "Made with React",
			imgUrl: projImg3,
			url: "github.com/bayani482",
		  },
	];

	return (
		<section className="project" id="project">
			<Container>
				<Row>
					<Col>
						<h2>Projects</h2>{" "}
						<Tab.Container
							id="projects-tabs"
							defaultActiveKey="first"
						>
							<Row>
								<Col>
									<Nav
										variant="pills"
										className="nav-pills mb-5 justifycontent-center align-items-center"
										id="pills-tab"
									></Nav>
								</Col>
								<Tab.Content>
									<Tab.Pane eventKey="first">
										<Row>
											{projects.map((project, index) => {
												return (
													<ProjectCard
														key={index}
														{...project}
													/>
												);
											})}
										</Row>
									</Tab.Pane>
								</Tab.Content>
							</Row>
						</Tab.Container>
					</Col>
				</Row>
			</Container>
		</section>
	);
};
