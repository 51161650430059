import React, { useState, useEffect, useCallback } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link as ScrollLink } from "react-scroll";


const NavBar = () => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = useCallback(() => {
    if (window.scrollY > lastScrollY) {
      // Scroll down
      setShow(false);
    } else {
      // Scroll up
      setShow(true);
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Navbar bg="dark" variant="dark" expand="sm" className={`navbar ${show ? 'navbar-show' : 'navbar-hide'}`}>
    <div className="navbar-container"> 
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <ScrollLink to="home" smooth={true} duration={100} className="nav-link">
            Home
          </ScrollLink>
          <ScrollLink to="skills" smooth={true} duration={100} className="nav-link">
            Skills
          </ScrollLink>
          <ScrollLink to="about" smooth={true} duration={100} className="nav-link">
          About Me
        </ScrollLink>
          <ScrollLink to="projects" smooth={true} duration={100} className="nav-link">
            Projects
          </ScrollLink>
        </Nav>
      </Navbar.Collapse>
      </div> 
    </Navbar>
  );
};

export default NavBar;