import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import headerImg from "../assets/computer_programming.png";
import ContactModal from "./ContactModal";

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState("");
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 1000;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            tick();
        }, delta);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [text, delta]);

    const toRotate = ["Full Stack Web Developer"];

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting
            ? fullText.substring(0, text.length - 1)
            : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting) {
            setDelta((prevDelta) => prevDelta / 2);
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updatedText === "") {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(100);
        }
    };

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagline">Welcome to my portfolio</span>
                        <h1>{"Hello, I'm Brian "}</h1>
                        <h2>
                            <span className="wrap"></span>
                            <span className="wrap">{text}</span>
                        </h2>
                        <Button onClick={handleShow}>Get in Touch</Button>

                        <ContactModal show={show} handleClose={handleClose} />
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <img src={headerImg} alt="Header Img" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};