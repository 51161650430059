import React from "react";
import { Banner } from "./Banner";
import { Skills } from "./Skills";
import { Projects } from "./Projects";
import { Element } from "react-scroll";
import AboutMe from "./AboutMe";

const Home = () => {
  return (
    <div>
      <Element name="home">
        <Banner />
      </Element>
      <Element name="skills">
        <Skills />
      </Element>
      <Element name="about">
        <AboutMe />
      </Element>
      <Element name="projects">
        <Projects />
      </Element>
    </div>
  );
};

export default Home;