import React from "react";
import { Modal, Button } from "react-bootstrap";
import EmailForm from "./EmailForm";

const ContactModal = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Contact us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EmailForm />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContactModal;
