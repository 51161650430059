import React from "react";
import headerImg from "../assets/self_img.jpg";

export const AboutMe = () => {
	return (
		<div className="about">
			<div className="aboutContainer">
				<h1>About Me</h1>
				<p>
					My passion is web development but I have experience in C++,
					Java, Javscript, and Python. I have a Technical background
					as a Elevator mechanic doing wiring, Maintance,
					Installation. In my free time I like to tinker with Arduino
					boards or my 3D Printer. I love to code.
				</p>
				
				<img className="aboutMeImg" src={headerImg} alt="Header Img" />
			</div>
		</div>
	);
};

export default AboutMe;
