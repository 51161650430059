import React from "react";
import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, url }) => {
	const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

	return (
		<Col sm={6} md={4}>
			<a href={formattedUrl} target="_blank" rel="noopener noreferrer" id="imgLinks">
				<div className="proj-imgbx">
					<img
						src={imgUrl}
						alt=""
						width="100%"
						height="100%"
						object-fit="fit"
					/>
					<div className="proj-txtx">
						<h4>{title}</h4>
						<span>{description}</span>
					</div>
				</div>
			</a>
		</Col>
	);
};
