import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import emailjs from "@emailjs/browser";

export const EmailForm = () => {
	const form = useRef();
	const [emailSent, setEmailSent] = useState(false);
	const serviceId = "service_iasozza";
	const templateId = "template_bmn2drg";
	const publicKey = "lo8cxicN6Lkr6ERBU";

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(serviceId, templateId, form.current, {
				publicKey: publicKey,
			})
			.then(
				() => {
					setEmailSent(true);
					console.log("SUCCESS!");
				},
				(error) => {
					console.log("FAILED...", error.text);
				}
			);
	};

	return (
		<div className="form-box">
			<form ref={form} onSubmit={sendEmail}>
				<Container>
					<div>
						<Row>
							<input
								type="text"
								name="user_name"
								placeholder="Name"
								id="name"
							/>
						</Row>
						<Row>
							<input
								type="email"
								name="user_email"
								placeholder="Email"
								id="eMail"
							/>
						</Row>
						<Row>
							<textarea name="message" placeholder="Message" id="mess" />
						</Row>
					</div>
					<div className="text-center formButton">
						<Row>
							<input type="submit" value="Send" id="submitButton" />
						</Row>
					</div>
					{emailSent && (
						<Row className="text-center" id="formConfirmation">
							<Col>
								<p>Your email has been sent. I will get back to as soon as possible. <br />Thank you!</p>
							</Col>
						</Row>
					)}
				</Container>
			</form>
		</div>
	);
};

export default EmailForm;