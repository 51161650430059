import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter1 from "../assets/java-original.svg";
import meter2 from "../assets/file-type-css.svg";
import meter3 from "../assets/c.svg";
import meter4 from "../assets/javascript-js.svg";
import meter5 from "../assets/mongodb-original-wordmark.svg";
import meter6 from "../assets/Node.js_logo.svg";
import meter7 from "../assets/python.svg";
import meter8 from "../assets/react.svg";
import meter9 from "../assets/icons8-firebase.svg";
import meter10 from "../assets/icons8-html.svg";

export const Skills = () => {
	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 6,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 5,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
		},
	};

	return (
		<section className="skill" id="skills">
			<Container>
				<Row>
					<Col>
						<div className="skill-bx">
							<h2>Skills</h2>
							<Carousel
								swipeable={true}
								draggable={true}
								responsive={responsive}
								infinite={true}
								className="skill-slider"
								autoPlaySpeed={1500}
								transitionDuration={500}
								autoPlay={true}
								showDots={true}
								arrows={true}
							>
								<div className="item">
									<img src={meter9} alt="image9" />
									<h5>FireBase</h5>
								</div>
								<div className="item">
									<img src={meter8} alt="image8" />
									<h5>React</h5>
								</div>
								<div className="item">
									<img src={meter5} alt="image5" />
									<h5>MongoDB</h5>
								</div>
								<div className="item">
									<img src={meter6} alt="image6" />
									<h5>NodeJs</h5>
								</div>
								<div className="item">
									<img src={meter10} alt="image10" />
									<h5>HTML</h5>
								</div>
								<div className="item">
									<img src={meter2} alt="image2" />
									<h5>CSS</h5>
								</div>
								<div className="item">
									<img src={meter4} alt="image4" />
									<h5>javascript</h5>
								</div>
								<div className="item">
									<img src={meter7} alt="image7" />
									<h5>Python</h5>
								</div>
								<div className="item">
									<img src={meter3} alt="image3" />
									<h5>C++</h5>
								</div>
								<div className="item">
									<img src={meter1} alt="image1" />
									<h5>Java</h5>
								</div>
							</Carousel>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};
